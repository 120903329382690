<template>
  <v-app>
    <Toast />
    <Auth v-if="!$store.state.user" />
    <v-main v-else-if="$store.state.DashHostName === businessLink">
    <!-- <v-main v-else-if="$store.state.DashHostName === 'business.anterocargo.id' || $store.state.DashHostName === 'localhost'"> -->
      <div class="main-container dashboard">
        <v-container>
          <BusinessTTKContent :customer="$store.state.user" />
        </v-container>
      </div>
    </v-main>
    <v-main v-else class="grey lighten-4">
      <div class="main-appbar white">
        <v-row no-gutters>
          <v-col cols="12" class="col-sm-12">
            <v-tabs
              fixed-tabs
              :height="48"
              background-color="bg-business-primary"
              dark
            >
              <v-tabs-slider :color="'secondary'" />
              <v-tab to="/" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-apps
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  {{ MyStoreName }}
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp', 'operation'], $store.state.user.role)" to="/shipping" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-truck-fast
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Shipping
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/member" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-account-group
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Data Client
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp', 'finance'], $store.state.user.role)" to="/finance" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-cash
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Finance
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/store-manager" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-office-building-marker
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Cabang & Vendor
                </span>
              </v-tab>
              <v-tab v-if="$role(['manager', 'svp'], $store.state.user.role)" to="/account-manager" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-account-cog-outline
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Account Manager
                </span>
              </v-tab>
              <!-- <v-tab v-if="$role([], $store.state.user.role)" to="/features/main-banner" class="text-capitalize ls-05 caption font-weight-bold">
                <v-icon small>
                  mdi-newspaper-variant-outline
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Berita / Artikel
                </span>
              </v-tab> -->
              <v-tab @click.prevent="DO_LOGOUT" class="text-capitalize ls-05 red--text white">
                <v-icon small color="red">
                  mdi-power
                </v-icon>
                <span class="hidden-sm-and-down ml-2">
                  Sign Out
                </span>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </div>
      <div class="main-container">
        <v-container fluid>
          <router-view/>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    businessLink: 'business.anterocargo.id'
  }),
  computed: {
    MyStoreName () {
      if (this.$store.state.user) {
        const store = this.$store.state.storeData.find(r => parseInt(r.id) === parseInt(this.$store.state.user.store))
        return store ? (store.code + ' - ' + store.name) : 'Dashboard'
      }
      return 'Dashboard'
    },
    ColorStoreActive () {
      // const storeActive = parseInt(this.$store.state.storeActive)
      const color = 'primary'
      // if (storeActive) {
      //   const store = this.$store.state.storeData.find(r => parseInt(r.id) === storeActive)
      //   if (store) {
      //     if (store.color) {
      //       return store.color
      //     }
      //   }
      //   const index = this.$store.state.storeData.findIndex(r => parseInt(r.id) === storeActive)
      //   switch (index) {
      //     case 0:
      //       color = 'primary'
      //       break
      //     case 1:
      //       color = 'secondary'
      //       break
      //     case 2:
      //       color = 'blue'
      //       break
      //     case 3:
      //       color = 'green'
      //       break
      //     case 4:
      //       color = 'teal'
      //       break
      //     case 5:
      //       color = 'lime darken-1'
      //       break
      //     case 6:
      //       color = 'orange'
      //       break
      //     case 7:
      //       color = 'indigo'
      //       break
      //     default:
      //       break
      //   }
      // }
      return color
    }
  },
  methods: {
    DO_LOGOUT () {
      if (!confirm('Logout ?')) {
        return false
      }
      localStorage.removeItem('t')
      this.$store.commit('USER', null)
    }
  }
}
</script>

<style lang="scss">
html, a, a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}
html {
  // height: 100vh!important;
  // overflow: hidden!important;
  >body {
    // overflow: hidden!important;
    >.v-application {
      // height: 100vh!important;
      // overflow: auto!important;
      .display-4,
      .display-3,
      .display-2,
      .display-1,
      .headline,
      .title,
      .subtitle-1,
      .subtitle-2,
      .body-1,
      .body-2,
      .caption,
      .overline {
        font-family: 'Poppins', sans-serif!important;
      }
    }
  }
}

.main-appbar {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
  height: 48px!important;
}
.main-container {
  padding-top: 48px;
  position: relative;
  // overflow-y: auto;
}
.c-pointer {
  cursor: pointer!important;
}
.fill-width {
  width: 100%!important;
}
.p-relative {
  position: relative!important;
}
.ls-0 {
  letter-spacing: 0px!important;
}
.ls-05 {
  letter-spacing: .5px!important;
}
.ls-1 {
  letter-spacing: 1px!important;
}
.lh-0 {
  line-height: 100%!important;
}
a {
  text-decoration: none!important;
  color: unset!important;
}
.v-card {
  border-radius: 0px!important;
}
.file-uploader {
  width: 0px;
  height: 0px;
  position: fixed;
  bottom: -100%;
  z-index: -2;
}
.v-tabs {
  .v-slide-group__prev,
  .v-slide-next {
    display: none!important;
  }
}
.theme--dark {
  &.btn-checkout {
    &.v-btn--disabled {
      &.v-btn--has-bg {
        &.v-btn {
          background-color: rgba(0, 0, 0, .1)!important;
          color: rgba(0, 0, 0, .3)!important;
        }
      }
    }
  }
}
.caption {
  letter-spacing: 0px!important;
}
.caption-small {
  font-size: 12px!important;
  letter-spacing: 0px;
}
.v-textarea {
  textarea {
    line-height: 125%!important;
  }
}
a {
  &.disabled {
    pointer-events: none!important;
  }
}

.v-calendar-monthly {
  border-radius: 4px!important;
  // overflow: hidden;
  &.v-calendar-weekly {
    @media only screen and (max-width: 768px) {
      border-radius: 0px!important;
      width: 117%;
      margin-left: -8.5%!important;
      margin-right: -8.5%!important;
      border: solid 0px!important;
    }
  }
  .v-calendar-weekly__head {
    .v-calendar-weekly__head-weekday {
      background-color: #262d44!important;
      padding: 4px 4px 4px 4px;
      font-weight: bold;
      color: #ffffff!important;
      &.v-outside {
        color: #9e959f!important;
      }
      &.v-present {
        color: #fcb068!important;
      }
    }
  }
  .v-calendar-weekly__day {
    min-height: 45px;
    padding: 0px 5px 10px 5px;
    .v-event {
      margin-top: 5px!important;
      width: 100%!important;
      height: 12px!important;
      display: flex!important;
      justify-content: flex-start!important;
      align-items: center!important;
      line-height: unset!important;
      // left: 55%;
      left: 0px;
      border-radius: 20px!important;
      >.booking-counter {
        position: relative;
        padding: 0px!important;
        font-size: .5rem!important;
        width: 100%;
        height: 100%;
        text-align: center;
        display:flex;
        justify-content: center!important;
        align-items: center!important;
        span {
          &.booking-counter-progress {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            background-color: #262d44;
            z-index: -1;
          }
        }
      }
    }
    :nth-child(2) {
      &.v-event {
        // margin-top: -10px!important;
        margin-top: 5px!important;
        margin-bottom: 5px!important;
        &.grey {
          background: transparent!important;
        }
        >.booking-counter {
          span {
            color: #262d44;
            font-size: .7rem;
            font-weight: bold;
            &.booking-counter-progress {
              display: none;
            }
          }
        }
      }
    }
    &.v-present {
      background: #fbede6;
    }
    >.v-calendar-weekly__day-label {
      margin-top: 10px;
      margin-bottom: 0px;
      .v-btn--fab {
        &.v-size--small {
          width: 25px!important;
          height: 25px!important;
        }
        &.primary {
          background-color: #f4700b!important;
          color: #fff!important;
        }
        &.transparent {
          background-color: #e3d9e9!important;
          color: #412a54!important;
        }
      }
    }
    .v-event-more {
      display: none;
    }
  }
}
table {
  thead {
    &.v-data-table-header {
      >tr {
        // background-color: #262d44!important;
        background-color: #262d44;
        background: linear-gradient(145deg, rgba(22,56,73,1) 30%, rgb(148, 207, 39) 100%)!important;
        >th {
          // height: 80!important;
          border-bottom: 0px!important;
          >i.theme--light,
          >span {
            color: #fff!important;
          }
          >i.theme--light {
            font-size: .8rem!important;
            margin-left: 5px!important;
            margin-top: -1px!important;
          }
          >.v-data-table__checkbox  {
            >.v-input--selection-controls__input {
              >i {
                &.theme--light {
                  &.v-icon {
                    color: #ffffff!important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.caption-small {
  font-size: .7rem!important;
}

@media screen {
  // .dialog-printed {
  //   display: none;
  // }
}
.print-only {
  display: none;
}
#dialogPrintPDF {
  &.row {
    margin: 0px!important;
    .col {
      padding: 2px!important;
      padding-bottom: 4px!important;
      &.col-left {
        padding-right: 2px!important;
        border-right: dashed 1px #000!important;
        border-bottom: dashed 1px #000!important;
      }
      &.col-right {
        padding-left: 8px!important;
        border-bottom: dashed 1px #000!important;
      }
    }
  }
}
@mixin print-area {
  body {
    font-size: 10px!important;
  }
  .print-only {
    display: block;
  }
  .v-application {
    width:100%!important;
    height:auto!important;
    >.v-application--wrap {
      display: none!important;
    }
  }
  .v-dialog__content {
    height: auto!important;
    max-height: unset!important;
    width: 100%!important;
    position: relative!important;
    align-items: flex-start!important;
    justify-content: start!important;
    .v-dialog {
      max-height: unset!important;
      &.dialog-printed {
        visibility:hidden!important;
        border-radius: 0px!important;
        margin: 0px!important;
        width:100%!important;
        .print-content {
          >.container {
            min-width: unset!important;
          }
        }
        >.v-card,
        >.v-card * {
          visibility: visible;
          overflow: visible !important;
        }
        >.v-card {
          box-shadow: none!important;
          position:absolute;
          left:0;
          top:0;
        }
        .no-print {
          display: none!important;
          visibility:hidden;
        }
        .abc-logo {
          height: 45px!important;
        }
        canvas {
          height: 120px!important;
          width: 120px!important;
        }
        .text-h5 {
          font-size: 12px!important;
        }
        .v-divider {
          display: none;
        }
        .primary {
          &.white--text {
            &.pa-2 {
              padding: 0px!important;
              color: #000!important;
            }
          }
        }
        .my-2 {
          margin-top: 0px!important;
          margin-bottom: 0px!important;
        }
        .row {
          margin: 0px!important;
          .col {
            padding: 2px!important;
            padding-bottom: 4px!important;
            &.col-left {
              padding-right: 2px!important;
              border-right: dashed 1px #000!important;
              border-bottom: dashed 1px #000!important;
            }
            &.col-right {
              padding-left: 8px!important;
              border-bottom: dashed 1px #000!important;
            }
          }
        }
        .pa-4 {
          padding: 4px!important;
        }
      }
    }
  }
}

html {
  &.print-area {
    @include print-area;
  }
}
@media print {
  html {
    &.overflow-y-hidden {
      overflow: auto!important;
    }
  }
  @include print-area;
}
// table {
//   &.table-print {
//     border-spacing: 0px;
//     border-collapse: collapse;  /* <--- add this so all the internal <td>s share adjacent borders  */
//     border: 1px solid black!important;
//     tr {
//       border: 1px solid rgba(0, 0, 0, .5)!important;
//       border-collapse: collapse;
//       border-spacing: 0px;
//       td {
//         border: 1px solid rgba(0, 0, 0, .5)!important;
//       }
//     }
//   }
// }
@media (min-width: 1264px) {
  .container {
    max-width: 100%!important;
  }
}

.bg-business-primary {
  background: #262d44;
  background: linear-gradient(145deg, rgba(22,56,73,1) 30%, rgb(148, 207, 39) 100%);
}
.bg-business-secondary {
  background: #262d44;
  background: linear-gradient(145deg, rgba(22,56,73,1) 30%, rgb(251, 140, 0) 100%);
}
.business-tabs-items {
  >.v-window__container {
    height: 100%!important;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  height: 40px!important;
}

.main-container {
  &.dashboard {
    padding: 0px!important;
    height: 100%!important;
    background-color: #f0f0f0;
    >.container {
      max-width: 1000px!important;
      height: 100%!important;
      padding: 0px!important;
      >.v-card {
        height: 100%!important;
        .v-toolbar__title {
          .mdi-chevron-down {
            display: none;
          }
        }
        .v-toolbar__items {
          >button {
            display: none;
            &.dashboard-logout {
              display: block!important;
            }
          }
        }
      }
      .confirm-delivery-and-update {
        display: none!important;
      }
    }
  }
}
.bg-glass {
  /* From https://css.glass */
  background: rgba(254, 254, 254, 0.46);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(254, 254, 254, 0.66);
}
</style>
